.main {
  display: flex;
  padding-top: 8rem;
  max-width: 1460px;
  position: relative;
}

.outlineColumn {
  flex: 0 0 345px;
  padding: 0 24px;
}

.docColumn {
  flex: 0 0 750px;
}

.outlineColumn.fixTop > div {
  position: fixed;
  top: 75px;
}

.outlineColumn div > ul {
  border-bottom: 1px solid #c1c1c6;
}

.outlineColumn ul {
  padding: 0;
  font-size: 1.4rem;
  border-left-style: unset;
}

.outlineColumn li::before {
  content: none;
}

.outlineColumn li > ul {
  padding-top: 15px;
  padding-left: 24px;
}

.showScrollbar div > ul {
  overflow-y: scroll;
  height: 75vh;
}

.outlineColumn a {
  color: #767678;
}

.outlineColumn a:hover {
  color: #a863ff;
  text-decoration: none;
}

.outlineColumn :global(.active) a {
  color: #6600ff;
}

.menu {
  display: none;
  transform: translateX(-200%);
  transition: transform 500ms ease-in-out;
}

.contact {
  bottom: 0;
  font-size: 15px;
  display: flex;
}

.contact a {
  padding-left: 10px;
  color: #6600ff;
}

.contact a:hover {
  color: #6600ff;
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .main {
    display: block;
  }

  .outlineColumn {
    display: none;
  }

  .outlineColumn > ul {
    border-bottom: 1px solid #c1c1c6;
    height: 80vh;
    overflow-y: scroll;
  }

  .docColumn {
    width: 75%;
    min-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .menu {
    display: unset;
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: white;
    width: 100vw;
    z-index: 1;
    color: #767678;
    overflow-y: scroll;
  }

  .showMenu {
    transform: translateX(0);
  }

  .showOutline {
    display: unset;
  }

  .closeBtn {
    padding: 15px 0px 15px 0px;
    font-size: 3rem;
    line-height: 1;
    background: none;
    border: none;
    color: #767678;
  }

  .menu ul li {
    margin-bottom: 1.2rem;
  }

  .contact {
    font-size: 1.7rem;
  }

  .disableScroll {
    overflow: hidden;
  }

  .enableScroll {
    overflow: unset;
  }
}

@media (max-width: 800px) {
  .docColumn {
    width: 90%;
  }
}
