.bar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #e8e8eb;
  font-size: 1.4rem;
  color: #767678;
  background-color: #fff;
  transform: translateY(-100%);
  transition: transform 250ms ease-in-out;
}

.bar.show {
  transform: translateY(0);
}

.title {
  flex: 0 0 345px;
  padding-left: 24px;
}

.appname {
  font-family: "Flama Semicondensed", Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  font-weight: 800;
  color: #252534;
  margin-right: 0.4rem;
}

.actions {
  flex: 1;
  padding-right: 24px;
  text-align: right;
}

.resetBtn {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.76976 2.23667C10.7364 3.20334 11.3364 4.53 11.3364 6.00333C11.3364 8.95 8.94976 11.33 6.00309 11.33C3.05643 11.33 0.66976 8.95 0.66976 6.00333C0.66976 3.51667 2.36976 1.44334 4.66976 0.850002V2.23667C3.11643 2.78334 2.00309 4.26334 2.00309 6.00333C2.00309 8.21 3.79643 10.0033 6.00309 10.0033C8.20976 10.0033 10.0031 8.21 10.0031 6.00333C10.0031 4.89667 9.54309 3.91 8.81643 3.19L6.66976 5.33667V0.670002H11.3364L9.76976 2.23667Z' fill='%236600FF'/%3E%3C/svg%3E%0A");
  background-position: right 5px center;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  margin-right: 24px;
  padding-right: 20px;
}

.iconContainer {
  display: none;
}

@media (max-width: 1100px) {
  .by {
    display: none;
  }

  .title {
    flex: 1.1;
    display: flex;
  }

  .iconContainer {
    display: inline-block;
    margin-right: 5px;
  }
}

@media (max-width: 800px) {
  .appname {
    font-size: 2rem;
  }

  .actions {
    flex: 1.4;
    padding-right: 24px;
  }

  .resetBtn {
    margin-right: 15px;
  }

  .title {
    padding-left: 18px;
  }

  .icon {
    display: block;
  }
}

@media (max-width: 360px) {
  .actions {
    flex: 1.5;
    padding-right: 10px;
  }

  .title {
    flex: 1.2;
  }
}
