.modal {
  position: fixed;
  right: 12px;
  top: 60px;
  width: 480px;
  padding: 24px;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 0 0 2px 2px;
  transition: transform 400ms ease-in-out;
  transform: translateY(-145%);
}

.resetModal {
  left: 50%;
  margin-left: -240px;
}

.background {
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
}

.resetModalIsVisible {
  transform: translateY(0);
}

.exportModalIsVisible {
  transform: translateY(0);
}

.modal h5 {
  margin-bottom: 0.6rem;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 15px;
  font-size: 2rem;
  line-height: 1;
  color: #767678;
  background: none;
  border: none;
}

.closeBtn:focus {
  outline: none;
  color: #3fabff;
}

.saveInput {
  background: #fafafc;
  border: 1px solid #e8e8eb;
  border-radius: 2px;
  width: 100%;
  padding: 10px 16px;
  font-size: 1.4rem;
  color: #252534;
  margin: 15px 0;
}

.saveInput:focus {
  border: 1px solid #3fabff;
  box-shadow: 0 0 0 1px #3fabff;
  outline: none;
}

.checkRow {
  margin-bottom: 12px;
  font-size: 1.4rem;
  margin-left: 25px;
}

.checkLabel {
  color: #252534;
  padding-left: 10px;
}

.submitRow {
  text-align: right;
  padding-top: 5px;
}

.exportCheckbox {
  top: 1px !important;
}

@media (max-width: 600px) {
  .resetModal {
    transform: translate(-50%, -500%);
    padding: 0px 24px 24px 24px;
    margin-left: 0px;
    width: 300px;
    top: 50%;
    left: 50%;
  }

  .exportModal {
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .resetModalIsVisible {
    transform: translate(-50%, -50%);
  }

  .checkLabel {
    font-size: 1.7rem;
  }

  .checkRow {
    font-size: 1.7rem;
  }
}
