.intro {
  width: 750px;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro p {
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.75);
}

.appmeta {
  display: block;
  font-family: Flama, Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: normal;
  color: #ffffff;
}

.appmeta a {
  color: #ffffff;
  text-decoration: underline;
  text-decoration-color: #a863ff;
}

.next {
  padding-top: 2rem;
  padding-bottom: 3rem;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 8L14.59 6.59L9 12.17V0H7V12.17L1.42 6.58L0 8L8 16L16 8Z' fill='%23C6C6C8'/%3E%3C/svg%3E%0A");
  background-position: bottom center;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
}

.title {
  width: 365px;
  position: relative;
  color: #ffffff;
}

.alpha {
  position: absolute;
  width: 57px;
  right: 15px;
  top: 1px;
}

.large {
  font-family: "Flama Semicondensed", Helvetica, Arial, sans-serif;
  font-size: 3.6rem;
  font-weight: normal;
  margin-bottom: 3rem;
  color: #ffffff;
}

.background {
  background: radial-gradient(
      1609.97px at 100% 0%,
      rgba(122, 45, 238, 0.4) 0%,
      rgba(122, 45, 238, 0) 100%
    ),
    radial-gradient(
      804.98px at 0% 0%,
      rgba(102, 0, 255, 0.5) 0%,
      rgba(102, 0, 255, 0) 100%
    ),
    #1f2866;
}

.icon {
  height: 160px;
  width: 152px;
}

@media (max-width: 1100px) {
  .intro {
    width: 75%;
    min-width: 320px;
  }
}

@media (max-width: 800px) {
  .intro {
    width: 90%;
    justify-content: start;
    padding-top: 50px;
  }

  .intro br {
    display: none;
  }

  .next {
    padding-top: 1rem;
  }

  .title {
    width: 240px;
  }

  .alpha {
    width: 42px;
    right: 18px;
    top: -2px;
  }

  .large {
    font-size: 2.4rem;
  }

  .background {
    background: radial-gradient(
        765.19px at 100% 0%,
        rgba(122, 45, 238, 0.4) 0%,
        rgba(122, 45, 238, 0) 100%
      ),
      radial-gradient(
        382.59px at 0% 0%,
        rgba(102, 0, 255, 0.5) 0%,
        rgba(102, 0, 255, 0) 100%
      ),
      #1f2866;
  }

  .icon {
    height: 136px;
    width: 129px;
  }
}

@media (max-height: 560px) {
  .intro {
    height: 140vh;
  }
}
