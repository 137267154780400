.primary {
  border: none;
  border-radius: 4px;
  background-color: #6600ff;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  padding: 6px 20px 8px;
  cursor: pointer;
}

.primary:disabled {
  background-color: #767678;
}

.secondary {
  border: none;
  background-color: transparent;
  color: #6600ff;
  cursor: pointer;
  font-size: 1.6rem;
  padding: 6px 20px 8px;
  white-space: nowrap;
}

.loading {
  display: inline-block;
  line-height: 0;
}

.export {
  min-width: 80px;
  text-align: center;
}
